import React, {  } from 'react'
import styled from 'styled-components'
import breakpoint from "styled-components-breakpoint"
import Flex from 'styled-flex-component'
import { AiFillWarning } from "react-icons/ai"
import { H1, P, Img } from "src/components"  
import { SEO, DeargenLogo } from "src/components/utils"  
import useContents from "src/data"


const NoIEPageRoot = styled(Flex)`
    width: 100vw;
    height: 100vh;
    text-align: center;

    ${breakpoint("mobile", "desktop")`
        h1 {
            font-size: 24px;
        }
        p {
            font-size: 16px;
        }
    `}
`

const DeargenLogoWrapper = styled.div`
    margin-top: 50px;
`

const DeargenCharactorImageWrapper = styled.div`
    position: absolute;
    width: 120px;
    height: 120px;
    right: 10vw;
    bottom: 10vh;

    img {
        width: 100%;
        height: 100%;
    }
    ${breakpoint("mobile", "desktop")`
        width: 100px;
        height: 100px;
        right: 20px;
        bottom: 20px;
    `}
`

export default function NoIEPage() {
    const seo = <SEO description="ww" />
    const { images } = useContents("UnderConstruction")

    return <NoIEPageRoot full center column>
        {seo}
        <AiFillWarning color="yellow" size={80} />
        <H1 weight="bold">Under Construction page</H1>
        <P>Please, access here using Chrome browser.</P>
        
        <DeargenLogoWrapper>
            <DeargenLogo />
        </DeargenLogoWrapper>

        <DeargenCharactorImageWrapper>
            <Img gatsbyImageSrc={images.deargen_charactor} />
        </DeargenCharactorImageWrapper>
    </NoIEPageRoot>
}
